import classNames from 'clsx';
import React, { useEffect } from 'react';
import type { InputBaseProps } from '../types/InputBase';

export type Color = 'primary' | 'success';

export type CheckboxProps = React.ComponentPropsWithoutRef<'input'> &
  InputBaseProps & {
    checked?: boolean;
    isIndeterminate?: boolean;
    color?: Color;
  };

export const InputCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      className,
      disabled,
      error,
      touched = true,
      id,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      required,
      value,
      form,
      isIndeterminate,
      color = 'primary',
      ...props
    },
    ref
  ) => {
    const showError = error && touched;

    const inputColors = {
      primary: 'focus:ring-indigo-500 text-indigo-600',
      success: 'focus:ring-emerald-600 text-emerald-600',
    };

    const inputClassnames = classNames(
      'h-4 w-4 rounded border',
      {
        'focus:ring-red-400 text-red-800 border-red-400': showError,
        [inputColors[color]]: !showError,
        'border-gray-300': !showError,
        'opacity-50': disabled,
      },
      className
    );

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.indeterminate = Boolean(isIndeterminate);
      }
    }, [isIndeterminate, ref]);

    return (
      <div>
        <label htmlFor={id} className="flex items-center text-gray-700">
          <div className="flex h-5 items-center">
            <input
              {...props}
              ref={ref}
              checked={checked}
              className={inputClassnames}
              disabled={disabled}
              id={id}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onClick={onClick}
              required={required}
              value={value}
              form={form}
              type="checkbox"
            />
          </div>
          <div className="flex gap-x-1 items-center">
            {label && <div className="ml-3 text-sm">{label}</div>}
            {required && <div className="text-red-600">*</div>}
          </div>
        </label>
        {showError && <p className="mt-1 text-sm font-normal text-red-700">{error}</p>}
      </div>
    );
  }
);

InputCheckbox.displayName = 'InputCheckbox';
