import type { ToastProps } from '@shape-construction/arch-ui/src/Toast/Toast';
import {
  dismissToast,
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from '@shape-construction/arch-ui/src/Toast/toasts';
import { type Draft, produce } from 'immer';
import { type SetStateAction, type WritableAtom, useAtom } from 'jotai';
import type { RESET } from 'jotai/utils';

const useQueueToasts = <T extends { id: string; status: 'pending' | 'completed' | 'failed' | 'expired' }>(
  queueName: string,
  jobQueueAtom: WritableAtom<T[], typeof RESET | SetStateAction<T[]>>,
  messages: {
    loading: string | ((job: T) => string);
    failed: string | ((job: T) => string);
    success: string;
  },
  actionOnSuccess: (job: T) => ToastProps['Actions']
) => {
  const [jobQueue, setJobQueue] = useAtom(jobQueueAtom);

  const showJobCompletedToast = (job: T, duration?: number) =>
    showSuccessToast({
      message: messages.success,
      id: job.id,
      duration: duration ?? Number.POSITIVE_INFINITY,
      onClose: () => removeFromCompletedJobs(job.id),
      Actions: actionOnSuccess(job),
      alignContent: 'start',
    });

  const showPendingJobToast = (job: T) => {
    const loadingMessage = typeof messages.loading === 'function' ? messages.loading(job) : messages.loading;
    return showInfoToast({
      message: loadingMessage,
      id: job.id,
      duration: Number.POSITIVE_INFINITY,
      loading: true,
      alignContent: 'start',
    });
  };

  const showFailedJobToast = (job: T) => {
    const failedMessage = typeof messages.failed === 'function' ? messages.failed(job) : messages.failed;
    return showErrorToast({
      message: failedMessage,
      id: job.id,
      duration: 5000,
      alignContent: 'start',
    });
  };

  const addToPendingJobs = (job: T) => {
    setJobQueue((prev) => [...prev, job]);
    showPendingJobToast(job);
  };

  const findJobIndexById = (draft: Draft<T>[], jobId: string): number => {
    return draft.findIndex((item) => item.id === jobId);
  };

  const addToCompletedJobs = (job: T, duration?: number) => {
    setJobQueue((prev: T[]) => {
      const nextState = produce(prev, (draft: Draft<T[]>) => {
        const itemIndex = findJobIndexById(draft, job.id);
        if (itemIndex !== -1) {
          draft[itemIndex] = job as Draft<T>;
        }
      });

      return nextState;
    });

    showJobCompletedToast(job, duration);
  };

  const removeFromCompletedJobs = (jobId: string) => {
    setJobQueue((prev) => {
      const updatedJobs = prev.filter((job) => job.id !== jobId);
      dismissToast(jobId);
      return updatedJobs;
    });
  };

  const initToastsFromStorage = (jobQueueArray: T[]) => {
    jobQueueArray?.forEach((job) => {
      if (job.status === 'pending') {
        showPendingJobToast(job);
      } else if (job.status === 'completed') {
        showJobCompletedToast(job);
      }
    });
  };

  return {
    jobQueue,
    addToPendingJobs,
    addToCompletedJobs,
    showFailedJobToast,
    removeFromCompletedJobs,
    initToastsFromStorage,
  };
};

export default useQueueToasts;
