import { useMediaQuery } from '@shape-construction/hooks';
import React from 'react';
import type { ButtonProps } from '../../Button/Button';
import { ModalBase } from '../../ModalBase/ModalBase';
import { breakpoints } from '../../utils/breakpoints';
import { cn } from '../../utils/classes';

export interface ConfirmationModalFooterProps extends React.ComponentProps<typeof ModalBase.Footer> {
  centered?: boolean;
}

export const ConfirmationModalFooter: React.FC<ConfirmationModalFooterProps> = ({
  children,
  className,
  centered,
  ...props
}) => {
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const Actions = React.Children.map(children, (child) => {
    if (!React.isValidElement<ButtonProps>(child)) return child;

    return React.cloneElement(child, { fullWidth: isSmallScreen || child.props.fullWidth });
  });

  return (
    <ModalBase.Footer
      {...props}
      className={cn(
        'flex flex-col-reverse space-x-0 space-y-2 space-y-reverse md:flex-row md:space-y-0 md:space-x-2',
        {
          'md:justify-center': centered,
        },
        className
      )}
    >
      {Actions}
    </ModalBase.Footer>
  );
};
