import { useMessageGetter } from '@messageformat/react';
import { Card, Popover, cn } from '@shape-construction/arch-ui';
import {
  ArrowUpOnSquareIcon,
  DevicePhoneMobileIcon,
  EllipsisVerticalIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { useSidebar } from '@shape-construction/arch-ui/src/Sidebar';
import React from 'react';

export const AppSidebarQRButton = ({ onClick }: { onClick: () => void }) => {
  const { isLargeScreen, open, toggleSidebar } = useSidebar();
  const isExpanded = !isLargeScreen || open;
  const getShapeAndChannels = useMessageGetter('appSidebar.getShapeAndChannelsOnMobile');
  const handleClick = () => {
    if (!isLargeScreen) toggleSidebar(false);
    onClick();
  };

  return (
    <Popover>
      <Popover.Trigger>
        <button
          type="button"
          aria-label={getShapeAndChannels('title')}
          className="flex text-left text-white hover:opacity-90 transition-all duration-200"
          onClick={handleClick}
        >
          <Card
            size="large"
            className={cn('bg-discovery-bold border-discovery-bold w-full', { 'w-10 h-10': !isExpanded })}
          >
            <Card.Body className={cn('flex p-2', { 'items-center justify-center': !isExpanded })}>
              <div className="flex gap-3">
                <div className="flex items-center w-5 h-5">
                  <DevicePhoneMobileIcon aria-hidden="true" />
                </div>
                <p className={cn('font-semibold hidden', { flex: isExpanded })}>{getShapeAndChannels('title')}</p>
              </div>
            </Card.Body>
          </Card>
        </button>
      </Popover.Trigger>
      <Popover.Content
        className="rounded-md shadow-lg text-xs leading-4 font-normal select-none z-popover max-w-[320px] max-md:w-screen"
        align="end"
        color="default"
        side="right"
        asChild
      >
        <div className="flex flex-col p-0">
          <div className="title flex items-center justify-center min-h-12 border-b">
            <h2 className="text-neutral-subtle text-sm">{getShapeAndChannels('popover.title')}</h2>
          </div>
          <div className="border-b">
            <div className="flex p-4 gap-2">
              <div className="flex flex-shrink-0">
                <img className="w-16 h-16" src="/images/qr-codes/shape.svg" alt="" />
              </div>
              <div className="flex flex-col gap-1 font-medium">
                <h3 className="text-sm">{getShapeAndChannels('popover.shape.title')}</h3>
                <ul className="list-disc text-xs leading-4 pl-4 text-neutral-subtle">
                  <li>
                    <span>
                      {getShapeAndChannels('popover.shape.step1a')}
                      <ArrowUpOnSquareIcon className="inline h-4 w-4 align-middle" />
                      {getShapeAndChannels('popover.shape.step1b')}
                      <EllipsisVerticalIcon className="inline h-4 w-4 align-middle" />
                      {getShapeAndChannels('popover.shape.step1c')}
                    </span>
                  </li>
                  <li>{getShapeAndChannels('popover.shape.step2')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="flex p-4 gap-2">
              <div className="flex flex-shrink-0">
                <img className="w-16 h-16" src="/images/qr-codes/channels.svg" alt="" />
              </div>
              <div className="flex flex-col gap-1 font-medium">
                <h3 className="text-sm">{getShapeAndChannels('popover.channels.title')}</h3>
                <ul className="list-disc text-xs leading-4 pl-4 text-neutral-subtle">
                  <li>{getShapeAndChannels('popover.channels.step1')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};
