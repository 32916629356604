import { Slot } from '@radix-ui/react-slot';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';
import { IconButton } from '../../Button';
import { XMarkIcon } from '../../Icons/solid';
import { cn } from '../../utils/classes';
import { type Align, type Color, type Side, arrowClasses, closeButtonColor, contentClasses } from '../config';

export type PopoverContentProps = React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
  /**
   * Color of the popover
   * @default 'default'
   */
  color?: Color;

  /** Position of the content around the trigger
   * @example `<Popover.Content side="top" ... />` positions the popover above the trigger
   * @default 'bottom'
   */
  side?: Side;

  /** Alignment of the content with respect to the trigger
   * @example
   * `<Popover.Content side="top" align="start" ... />` aligns the popover to the left
   * `<Popover.Content side="right" align="start" ... />` aligns the popover to the top
   * @default 'center'
   */
  align?: Align;

  /** Offset of the popover in pixels
   * @default 8
   */
  sideOffset?: TooltipPrimitive.PopperContentProps['sideOffset'];

  /**
   * Called when the close button is clicked on the popover
   * If not specified, doesn't show the "x" close button
   */
  onClose?: () => void;

  /** Hides the popover arrow if true
   * @default false
   */
  hideArrow?: boolean;

  /** Hides the close icon if true when onClose is provided
   * @default false
   */
  hideCloseIcon?: boolean;

  /**
   * Use the children as a slot, removing default styling and wrapper
   * @default false
   */
  asChild?: boolean;
};

export const PopoverContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, PopoverContentProps>(
  (
    {
      className,
      color = 'default',
      sideOffset = 8,
      align = 'center',
      side = 'bottom',
      children,
      onClose,
      hideArrow = false,
      hideCloseIcon = false,
      asChild,
      ...props
    },
    ref
  ) => {
    return (
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          ref={ref}
          sideOffset={sideOffset}
          onPointerDownOutside={onClose}
          onEscapeKeyDown={onClose}
          side={side}
          align={align}
          role="dialog"
          aria-label="dialog"
          aria-labelledby="popover-heading popover-body"
          className={cn(
            {
              'rounded-md shadow-lg text-xs leading-4 font-normal select-none z-popover max-w-[320px] max-md:w-screen p-4':
                !asChild,
            },
            contentClasses[color],
            className
          )}
          {...props}
        >
          {asChild ? (
            <Slot>{children}</Slot>
          ) : (
            <div className={cn('text-md max-h-[inherit]', { 'mr-4': !hideCloseIcon })}>
              {onClose && !hideCloseIcon && (
                <div className="absolute top-0.5 right-0.5 cursor-pointer opacity-50">
                  <IconButton
                    aria-label="popover-close-icon"
                    size="md"
                    color={closeButtonColor[color]}
                    icon={XMarkIcon}
                    onClick={onClose}
                    variant="text"
                  />
                </div>
              )}
              {children}
            </div>
          )}
          {!hideArrow && <TooltipPrimitive.Arrow className={cn('w-3 h-2 fill-current', arrowClasses[color])} />}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    );
  }
);

PopoverContent.displayName = 'Popover.Content';
