import type { SidebarIconColor, SidebarIconVariant } from './sidebar-config';

const colorVariantClassesMap: Record<NonNullable<SidebarIconColor>, Record<NonNullable<SidebarIconVariant>, string>> = {
  primary: {
    contained: 'bg-brand-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-brand text-icon-brand rounded-md',
    text: 'text-indigo-600',
  },
  secondary: {
    contained: 'bg-neutral-subtle-hovered rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-neutral-bold text-icon-neutral rounded-md',
    text: 'text-icon-neutral group-data-[active=true]:text-icon-selected',
  },
  success: {
    contained: 'bg-accent-green-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-green-500 text-icon-success rounded-md',
    text: 'text-icon-success',
  },
  warning: {
    contained: 'bg-accent-yellow-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-yellow-500 text-icon-accent-yellow rounded-md',
    text: 'text-icon-warning',
  },
  danger: {
    contained: 'bg-accent-red-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-red-500 text-icon-accent-red rounded-md',
    text: 'text-icon-danger',
  },
  pink: {
    contained: 'bg-accent-pink-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-pink-500 text-icon-accent-pink-subtle rounded-md',
    text: 'text-icon-accent-pink',
  },
  blue: {
    contained: 'bg-accent-blue-bold text-icon-neutral-inverse rounded-md',
    outlined: 'outline outline-1 -outline-offset-1 outline-blue-500 text-icon-accent-blue-subtle rounded-md',
    text: 'text-icon-accent-blue',
  },
};

type GetSidebarIconClassesProps = {
  color: SidebarIconColor;
  variant: SidebarIconVariant;
};

export const getSidebarIconClasses = ({ color, variant }: GetSidebarIconClassesProps) => {
  const colorVariantClasses = colorVariantClassesMap[color][variant];
  return colorVariantClasses;
};
