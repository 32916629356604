import {
  getGetApiProjectsProjectIdPeopleQueryKey,
  getGetApiProjectsProjectIdPeopleQueryOptions,
  getGetApiProjectsProjectIdPeopleTeamMemberIdQueryOptions,
  useGetApiProjectsProjectIdPeople,
  useGetApiProjectsProjectIdPeopleTeamMemberId,
  useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies,
  usePatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId,
  usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive,
  usePostApiTeamMembers,
} from '@shape-construction/api/api';
import {
  type IssueGroupCountEntitySchemaIdentifier,
  TeamMemberRoleSchema,
  type TeamMemberSchema,
  TeamMemberStatusSchema,
  type TeamSchema,
} from '@shape-construction/api/model';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useCurrentUser } from '../users/users';

// queries
export const useProjectPeople = useGetApiProjectsProjectIdPeople;
export const getProjectPeopleQueryOptions = getGetApiProjectsProjectIdPeopleQueryOptions;

export const useProjectPersonDependencies = useGetApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdIssueDependencies;

export const useProjectPerson = useGetApiProjectsProjectIdPeopleTeamMemberId;
export const getProjectPersonQueryOptions = getGetApiProjectsProjectIdPeopleTeamMemberIdQueryOptions;

export const useProjectMeQueryOptions = (...args: Parameters<typeof getProjectPeopleQueryOptions>) => {
  const [projectId, params, options] = args;
  const currentUser = useCurrentUser();

  return getProjectPeopleQueryOptions(projectId, params, {
    query: {
      ...options?.query,
      select: (data) => data.find(({ user }) => user.id === currentUser?.id),
    },
  });
};

export const useProjectPersonGetter = (
  projectId: Parameters<typeof useProjectPeople>[0],
  params?: Parameters<typeof useProjectPeople>[1]
) => {
  const { data } = useSuspenseQuery(getProjectPeopleQueryOptions(projectId, params));

  return useCallback(
    (memberTeamId: IssueGroupCountEntitySchemaIdentifier) => data?.find(({ id }) => id === memberTeamId),
    [data]
  );
};

export const useTeamMembers = (
  projectId: Parameters<typeof useProjectPeople>[0],
  teamId: TeamSchema['id'],
  status: TeamMemberSchema['status'] | Array<TeamMemberSchema['status']>
) =>
  useProjectPeople(
    projectId,
    { team_id: teamId, status },
    {
      query: {
        select: (data) => data.sort((personA, personB) => personA.user.name.localeCompare(personB.user.name)),
      },
    }
  );

// mutations
export const useUpdateTeamMember = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdTeamsTeamIdMembersTeamMemberId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdPeopleQueryKey(variables.projectId, {
            team_id: variables.teamId,
          }),
        });
      },
    },
  });
};

export const useArchiveTeamMember = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeamsTeamIdMembersTeamMemberIdArchive({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdPeopleQueryKey(variables.projectId, {
            team_id: variables.teamId,
          }),
        });
      },
    },
  });
};

export const useTeamMembersCreate = usePostApiTeamMembers;

// selectors

// issues can be assigned to people who are invited but haven't joined the project yet.
const joinedAndInvitedContributerParams: NonNullable<Parameters<typeof useProjectPeople>>[1] = {
  status: [TeamMemberStatusSchema.joined, TeamMemberStatusSchema.invited],
  role: [TeamMemberRoleSchema.contributor, TeamMemberRoleSchema.admin, TeamMemberRoleSchema.owner],
};

export const getJoinedAndInvitedContributorsQueryOptions = (
  ...args: Parameters<typeof getProjectPeopleQueryOptions>
) => {
  const [projectId] = args;
  return getProjectPeopleQueryOptions(projectId, joinedAndInvitedContributerParams);
};

const joinedContributorParams: NonNullable<Parameters<typeof useProjectPeople>>[1] = {
  status: TeamMemberStatusSchema.joined,
  role: [TeamMemberRoleSchema.contributor, TeamMemberRoleSchema.admin, TeamMemberRoleSchema.owner],
};

export const getProjectContributorsQueryOptions = (...args: Parameters<typeof getProjectPeopleQueryOptions>) => {
  const [projectId] = args;
  return getProjectPeopleQueryOptions(projectId, joinedContributorParams);
};
