import React from 'react';
import { ModalBase } from '../../ModalBase';
import { cn } from '../../utils/classes';

export type ConfirmationModalTitleProps = React.ComponentProps<typeof ModalBase.Title>;

export const ConfirmationModalTitle: React.FC<ConfirmationModalTitleProps> = ({ className, ...props }) => {
  const classes = cn('text-lg leading-6 font-medium', className);

  return <ModalBase.Title className={classes} {...props} />;
};
