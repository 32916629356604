import { useMediaQuery } from '@shape-construction/hooks';
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { breakpoints } from '../utils/breakpoints';
import { cn } from '../utils/classes';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_ICON } from './sidebar-config';

type SidebarContext = {
  open: boolean;
  isLargeScreen: boolean;
  toggleSidebar: (value?: boolean) => void;
  sidebarContentRef: React.RefObject<React.ElementRef<'div'>>;
  submenuContainerRef: React.RefObject<React.ElementRef<'div'>>;
};

const SidebarContext = createContext<SidebarContext | null>(null);

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider.');
  }

  return context;
};

export const SidebarProvider = forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'> & {
    defaultOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean, isLargeScreen?: boolean) => void;
  }
>(({ defaultOpen = true, open: controlledOpen, onOpenChange, className, style, children, ...props }, ref) => {
  const sidebarContentRef = useRef<React.ElementRef<'div'>>(null);
  const submenuContainerRef = useRef<React.ElementRef<'div'>>(null);

  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  const [uncontrolledOpen, setUncontrolledOpen] = useState(defaultOpen);
  const [uncontrolledOpenMobile, setUncontrolledOpenMobile] = useState(false);

  const open = controlledOpen ?? (isLargeScreen ? uncontrolledOpen : uncontrolledOpenMobile);

  const toggleSidebar = useCallback(
    (value?: any) => {
      return isLargeScreen
        ? setUncontrolledOpen((open) => (typeof value === 'boolean' ? value : !open))
        : setUncontrolledOpenMobile((open) => (typeof value === 'boolean' ? value : !open));
    },
    [isLargeScreen, setUncontrolledOpenMobile, setUncontrolledOpen]
  );

  useEffect(() => {
    if (onOpenChange) onOpenChange(open);
  }, [open]);

  const contextValue = useMemo<SidebarContext>(
    () => ({
      open,
      isLargeScreen,
      toggleSidebar,
      sidebarContentRef,
      submenuContainerRef,
    }),
    [open, isLargeScreen, toggleSidebar]
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      <div
        style={
          {
            '--sidebar-width': SIDEBAR_WIDTH,
            '--sidebar-width-icon': SIDEBAR_WIDTH_ICON,
            ...style,
          } as React.CSSProperties
        }
        className={cn(
          'group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-neutral-alpha-bold',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    </SidebarContext.Provider>
  );
});
SidebarProvider.displayName = 'Sidebar.Provider';
