import * as Dialog from '@radix-ui/react-dialog';
import React, { forwardRef } from 'react';
import Badge from '../Badge';
import { SHAPE, SIZE, THEME } from '../Badge/Badge.types';
import Divider from '../Divider';
import * as Tooltip from '../Tooltip';
import { cn } from '../utils/classes';
import { useSidebar } from './Sidebar.context';
import { getSidebarIconClasses } from './SidebarIconClasses';
import {
  SIDEBAR_WIDTH_MOBILE,
  type SidebarCollapsible,
  type SidebarIconColor,
  type SidebarIconVariant,
  type SidebarSide,
  type SidebarVariant,
} from './sidebar-config';

export const SidebarBadge = ({ label }: React.ComponentProps<typeof Badge>) => {
  const { open } = useSidebar();

  return (
    <span
      className={cn('ml-auto [&>span]:rounded-full', {
        'absolute -top-1 -right-1 [&>span]:border [&>span]:border-white group-data-[sidebar=submenu-content]:!static':
          !open,
      })}
    >
      <Badge label={label} shape={SHAPE.BASIC} size={SIZE.EXTRA_SMALL} theme={THEME.RED} />
    </span>
  );
};

export const SidebarContent = ({ className, ...props }: React.ComponentProps<'div'>) => {
  const { sidebarContentRef } = useSidebar();

  return (
    <div
      ref={sidebarContentRef}
      data-sidebar="content"
      className={cn(
        'relative flex min-h-0 flex-1 flex-col gap-2 p-2  overflow-auto group-data-[state=collapsed]:items-center group-data-[collapsible=icon]:overflow-x-hidden',
        className
      )}
      {...props}
    />
  );
};

export const SidebarFooter = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div data-sidebar="footer" className={cn('flex flex-col gap-2', className)} {...props} />
);

export const SidebarHeader = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div data-sidebar="header" className={cn('flex flex-col gap-2', className)} {...props} />
);

export type SidebarIconProps = {
  icon: React.ElementType;
  color?: SidebarIconColor;
  variant?: SidebarIconVariant;
};
export const SidebarIcon = ({
  icon: Icon,
  color = 'secondary',
  variant = 'text',
  className,
  ...props
}: Omit<React.ComponentProps<'div'>, 'children'> & SidebarIconProps) => {
  const classes = getSidebarIconClasses({ color, variant });
  return (
    <div
      data-sidebar="icon"
      className={cn(
        'h-7 grid items-center justify-center shrink-0 group-data-[state=collapsed]:w-full group-data-[state=expanded]:w-7 group-data-[sidebar=submenu-content]:!w-7',
        classes
      )}
      {...props}
    >
      {<Icon className="h-5 w-5" />}
    </div>
  );
};

export type SidebarItemProps = { active?: boolean; tooltip?: React.ReactNode } & React.ComponentProps<'button'>;
export const SidebarItem = forwardRef<React.ElementRef<'button'>, SidebarItemProps>(
  ({ children, className, active, disabled, tooltip, ...props }, ref) => {
    const { open } = useSidebar();

    return (
      <li data-sidebar="item" className="list-none relative">
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <button
              ref={ref}
              {...props}
              aria-expanded={!!open}
              data-active={active}
              disabled={disabled}
              className={cn(
                'group truncate h-10 w-full my-px p-1.5 rounded flex flex-nowrap items-center gap-3 font-medium text-sm bg-neutral-alpha-subltest disabled:opacity-50 disabled:pointer-events-none',
                {
                  'active:bg-neutral-alpha-subltest-pressed hover:bg-neutral-subtlest-hovered': !active,
                  'bg-selected text-selected active:bg-selected-pressed hover:bg-selected-hovered': active,
                },
                className
              )}
            >
              {children}
            </button>
          </Tooltip.Trigger>
          <Tooltip.Content side="right" hidden={open || !tooltip}>
            {tooltip}
          </Tooltip.Content>
        </Tooltip.Root>
      </li>
    );
  }
);

export const SidebarSection = ({
  children,
  className,
  divider,
  ...props
}: React.ComponentProps<'div'> & {
  divider?: boolean;
}) => {
  return (
    <>
      <div
        data-sidebar="section"
        className={cn(
          'relative flex flex-col w-full group-data-[state=collapsed]:w-10 group-data-[sidebar=submenu-content]:!w-full min-w-0',
          className
        )}
        {...props}
      >
        {children}
      </div>
      {divider && (
        <div className="my-0.5 w-full">
          <Divider orientation="horizontal" />
        </div>
      )}
    </>
  );
};

export type SidebarRootProps = React.ComponentProps<'div'> & {
  side?: SidebarSide;
  variant?: SidebarVariant;
  collapsible?: SidebarCollapsible;
};
export const SidebarRoot = ({
  side = 'left',
  variant = 'sidebar',
  collapsible = 'icon',
  className,
  children,
  ...props
}: SidebarRootProps) => {
  const { isLargeScreen, open, toggleSidebar, submenuContainerRef } = useSidebar();

  if (collapsible === 'none') {
    return (
      <div
        className={cn('flex h-full w-[--sidebar-width] flex-col bg-white text-sidebar-foreground', className)}
        data-testid="sidebar"
        {...props}
      >
        {children}
      </div>
    );
  }

  if (!isLargeScreen)
    return (
      <Dialog.Root open={open} onOpenChange={(value) => toggleSidebar(value)}>
        <Dialog.Trigger />
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <Dialog.Content
            data-sidebar="sidebar"
            data-mobile="true"
            data-testid="sidebar"
            className={cn(
              'w-[--sidebar-width] bg-white p-0 [&>button]:hidden',
              'fixed z-50 gap-4 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
              'inset-y-0 left-0 h-full border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm'
            )}
            style={
              {
                '--sidebar-width': SIDEBAR_WIDTH_MOBILE,
              } as React.CSSProperties
            }
          >
            <Dialog.Title />
            <div className="flex h-full w-full flex-col" {...props}>
              {children}
            </div>
            <Dialog.Description />
            <Dialog.Close />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );

  return (
    <div
      className="group peer hidden md:flex"
      data-state={open ? 'expanded' : 'collapsed'}
      data-collapsible={open ? '' : collapsible}
      data-variant={variant}
      data-side={side}
      data-testid="sidebar"
    >
      <div
        className={cn(
          'duration-200 relative h-full w-[--sidebar-width] bg-transparent transition-[width] ease-linear',
          'group-data-[collapsible=offcanvas]:w-0',
          'group-data-[side=right]:rotate-180',
          {
            'absolute right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]':
              side === 'right',
            'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]':
              variant === 'floating' || variant === 'inset',
            'group-data-[collapsible=icon]:w-[--sidebar-width-icon]': variant === 'sidebar',
          }
        )}
      />
      <div
        className={cn(
          'duration-200 absolute inset-y-0 z-10 hidden w-[--sidebar-width] transition-[left,right,width] ease-linear md:flex',
          {
            'left-0  group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]': side === 'left',
            'right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]': side === 'right',
            'p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]':
              variant === 'floating' || variant === 'inset',
            'group-data-[collapsible=icon]:w-[--sidebar-width-icon] group-data-[side=left]:border-r group-data-[side=right]:border-l':
              variant === 'sidebar',
          }
        )}
        {...props}
      >
        <div
          data-sidebar="sidebar"
          className={cn(
            'flex h-full w-full flex-col bg-white group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:shadow',
            className
          )}
        >
          {children}
        </div>
      </div>
      <div
        ref={submenuContainerRef}
        data-sidebar="submenu-container"
        className={cn(
          'w-0 group-data-[state=expanded]:hidden group-has-[[data-sidebar=submenu-content]]:w-[--sidebar-width] duration-200 transition-[width] ease-linear',
          {
            'absolute inset-y-0 right-[--sidebar-width-icon]': side === 'right',
          }
        )}
      />
    </div>
  );
};
SidebarRoot.displayName = 'Sidebar.Root';
