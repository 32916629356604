import React from 'react';
import { ModalBase, type ModalBaseProps } from '../ModalBase/ModalBase';
import { cn } from '../utils/classes';
import { ConfirmationModalContent } from './components/ConfirmationModalContent';
import { ConfirmationModalFooter } from './components/ConfirmationModalFooter';
import { ConfirmationModalHeader } from './components/ConfirmationModalHeader';
import { ConfirmationModalImage } from './components/ConfirmationModalImage';
import { ConfirmationModalTitle } from './components/ConfirmationModalTitle';

type Components = Pick<typeof ModalBase, 'SubTitle'> & {
  Header: typeof ConfirmationModalHeader;
  Title: typeof ConfirmationModalTitle;
  Image: typeof ConfirmationModalImage;
  Content: typeof ConfirmationModalContent;
  Footer: typeof ConfirmationModalFooter;
};

export type ConfirmationModalProps = Pick<
  ModalBaseProps,
  'children' | 'className' | 'initialFocus' | 'open' | 'onClose'
>;

export const ConfirmationModalRoot: React.FC<ConfirmationModalProps> = ({ children, className, ...props }) => (
  <ModalBase roundBorders className={cn('p-4 w-[344px] md:w-[512px]', className)} {...props}>
    {children}
  </ModalBase>
);

export const ConfirmationModal: typeof ConfirmationModalRoot & Components = Object.assign(
  ConfirmationModalRoot,
  ModalBase,
  {
    Header: ConfirmationModalHeader,
    Title: ConfirmationModalTitle,
    Image: ConfirmationModalImage,
    Content: ConfirmationModalContent,
    Footer: ConfirmationModalFooter,
  }
);
