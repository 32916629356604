export const sides = ['top', 'right', 'bottom', 'left'] as const;

export const aligns = ['start', 'center', 'end'] as const;

export const colors = ['dark', 'default', 'discovery'] as const;

export type Color = (typeof colors)[number];

export type Align = (typeof aligns)[number];

export type Side = (typeof sides)[number];

export const contentClasses = {
  dark: 'bg-gray-800 text-white',
  default: 'bg-white text-gray-800 border border-gray-200',
  discovery: 'bg-purple-500 text-white',
};

export const arrowClasses = {
  dark: 'text-gray-800',
  default: 'text-white stroke-gray-300',
  discovery: 'text-purple-500',
};

export const closeButtonColor = {
  dark: 'white',
  default: 'secondary',
  discovery: 'white',
} as const;
