import { useMessageGetter } from '@messageformat/react';
import { useMemo } from 'react';

export type CriticalValue = 'all' | 'critical' | 'not_critical';

type Option = {
  name: string;
  value: CriticalValue;
};

export const useCriticalOptions = () => {
  const optionsMessages = useMessageGetter('filters.toolbar.criticalSelectFilter');

  const options: Option[] = useMemo(() => {
    return [
      {
        name: optionsMessages('options.all'),
        value: 'all',
      },
      {
        name: optionsMessages('options.critical'),
        value: 'critical',
      },
      {
        name: optionsMessages('options.notCritical'),
        value: 'not_critical',
      },
    ];
  }, [optionsMessages]);

  return options;
};

export const mapCriticalValue = (selectedValue?: CriticalValue | null): boolean | undefined => {
  switch (selectedValue) {
    case 'critical':
      return true;
    case 'not_critical':
      return false;
    default:
      return undefined;
  }
};
