import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import {
  AdjustmentsHorizontalIcon,
  ChartBarSquareIcon,
  CpuChipIcon,
  LightBulbIcon,
  LockOpenIcon,
  MapPinIcon,
  NewspaperIcon,
  ShapeIssueTrackerIcon,
  ShapeProjectGalleryIcon,
  ShapeShiftReportIcon,
  ShapeWeeklyPlannerIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { ShapeChannelsIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useFeatureFlag } from '@shape-construction/feature-flags';
import { useProjectChannelsCount } from 'app/channels/queries/useProjectChannels';

export type SidebarItemType = {
  key: string;
  title: string;
  image: React.ElementType;
  route: string;
  isActiveOn?: string;
  notifications?: number;
  disabled?: boolean;
  hidden?: boolean;
};

export const useOverviewItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const labels = useMessageGetter('navigation');
  const { value: isDataHealthDashboardEnabled } = useFeatureFlag('data-health-dashboard');
  const isDisabled = !currentProject || currentProject?.currentTeamMemberStatus !== 'joined';

  const notificationsCount = useProjectChannelsCount(currentProject?.id);

  return [
    {
      key: 'timeline',
      title: labels('timeline'),
      image: NewspaperIcon,
      route: `/projects/${currentProject?.id}/timeline`,
      isActiveOn: '/projects/:projectId/timeline/*',
      disabled: isDisabled,
    },
    {
      key: 'issues',
      title: labels('issues'),
      image: ShapeIssueTrackerIcon,
      route: `/projects/${currentProject?.id}/issues`,
      isActiveOn: '/projects/:projectId/issues/*',
      disabled: isDisabled,
    },
    {
      key: 'shift_reports',
      title: labels('shiftReports'),
      image: ShapeShiftReportIcon,
      route: `/projects/${currentProject?.id}/shift-reports/drafts`,
      isActiveOn: '/projects/:projectId/shift-reports/*',
      disabled: isDisabled,
    },
    {
      key: 'activities',
      title: 'Activities',
      image: WrenchScrewdriverIcon,
      route: `/projects/${currentProject?.id}/activities`,
      isActiveOn: '/projects/:projectId/activities/*',
      disabled: isDisabled,
    },
    {
      key: 'weekly_planner',
      title: labels('weeklyPlanner'),
      image: ShapeWeeklyPlannerIcon,
      route: `/projects/${currentProject?.id}/weekly-planner/plans`,
      isActiveOn: '/projects/:projectId/weekly-planner/*',
      disabled: isDisabled,
    },
    {
      key: 'dashboard',
      title: labels('dashboard'),
      image: ChartBarSquareIcon,
      route: `/projects/${currentProject?.id}/dashboard`,
      isActiveOn: '/projects/:projectId/dashboard/*',
      disabled: isDisabled,
      hidden: isDataHealthDashboardEnabled,
    },
    {
      key: 'dashboards',
      title: labels('dashboard'),
      image: ChartBarSquareIcon,
      route: `/projects/${currentProject?.id}/dashboards`,
      isActiveOn: '/projects/:projectId/dashboards/*',
      disabled: isDisabled,
      hidden: !isDataHealthDashboardEnabled,
    },
    {
      key: 'gallery',
      title: labels('gallery'),
      image: ShapeProjectGalleryIcon,
      route: `/projects/${currentProject?.id}/gallery`,
      isActiveOn: '/projects/:projectId/gallery/*',
      disabled: isDisabled,
    },
    {
      key: 'channels',
      title: labels('channels'),
      image: ShapeChannelsIcon,
      route: `/projects/${currentProject?.id}/channels`,
      isActiveOn: '/projects/:projectId/channels/*',
      disabled: isDisabled,
      notifications: notificationsCount,
    },
  ].filter((s) => !s.hidden);
};

export const useAdminItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const items = [];
  const labels = useMessageGetter('admin.menu');

  if (currentProject?.availableActions?.manageLocations) {
    items.push({
      key: 'locations',
      title: labels('locations'),
      image: MapPinIcon,
      route: `/projects/${currentProject.id}/settings/locations`,
      isActiveOn: '/projects/:projectId/settings/locations/*',
    });
  }

  if (currentProject?.availableActions?.manageDisciplines) {
    items.push({
      key: 'disciplines',
      title: labels('disciplines'),
      image: LightBulbIcon,
      route: `/projects/${currentProject.id}/settings/disciplines`,
      isActiveOn: '/projects/:projectId/settings/disciplines/*',
    });
  }

  items.push(
    {
      key: 'teams',
      title: labels('teams'),
      image: UserGroupIcon,
      route: `/projects/${currentProject?.id}/settings/teams`,
      isActiveOn: '/projects/:projectId/settings/teams/*',
    },
    {
      key: 'access_requests',
      title: labels('accessRequests'),
      image: LockOpenIcon,
      route: `/projects/${currentProject?.id}/settings/access-requests`,
      isActiveOn: '/projects/:projectId/settings/access-requests/*',
    },
    {
      key: 'custom_fields',
      title: labels('customFields'),
      image: CpuChipIcon,
      route: `/projects/${currentProject?.id}/settings/custom-fields`,
      isActiveOn: '/projects/:projectId/settings/custom-fields/*',
    },
    {
      key: 'resources',
      title: labels('resources'),
      image: SquaresPlusIcon,
      route: `/projects/${currentProject?.id}/settings/resources`,
      isActiveOn: '/projects/:projectId/settings/resources/*',
    },
    {
      key: 'project',
      title: labels('projectSettings'),
      image: AdjustmentsHorizontalIcon,
      route: `/projects/${currentProject?.id}/settings/project`,
      isActiveOn: '/projects/:projectId/settings/project/*',
    }
  );

  return items;
};

export const useSidebarItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const overviewItems = useOverviewItems(currentProject);
  const adminItems = useAdminItems(currentProject);

  return [...overviewItems, ...adminItems];
};
