import { booleanFlag, config } from './src/utils/configHelpers';

/**
 * Feature flags configuration.
 *
 * @see {import('./types').FlagConfig} for more information on the configuration options.
 *
 * @example
 * ## For boolean flags -
 * ```js
 * config({
 *   // ...
 *   dark_mode: booleanFlag({ expiry: '2023-12-15' }),
 * })
 * ```
 *
 * ## For variant flags -
 * ```js
 * config({
 *   // ...
 *   new_issue_redesign: variantFlag<'A' | 'B'>({ // Variant keys for better type information
 *     target: 'team', // Targets team for gradual rollout and A/B
 *     default: 'B', // Default value for flag
 *     expiry: '2023-12-15',
 *   }),
 * })
 * ```
 */
export const FEATURE_FLAGS_CONFIG = config({
  'activities-insights-overview': booleanFlag({
    expiry: '2025-02-20',
  }),
  'enable-activity-readiness': booleanFlag({
    expiry: '2025-03-10',
  }),
  'issue-publish-from-create': booleanFlag({
    expiry: '2025-03-10',
  }),
  'archive-weekly-work-plan': booleanFlag({
    expiry: '2025-04-24',
  }),
  'timeline-v2': booleanFlag({
    expiry: '2025-04-24',
  }),
  'data-health-dashboard': booleanFlag({
    expiry: '2025-04-18',
  }),
  'add-activitives-to-work-plan': booleanFlag({
    expiry: '2025-04-24',
  }),
  'in-app-download': booleanFlag({
    expiry: '2025-04-24',
  }),
  'integrate-readiness': booleanFlag({
    expiry: '2025-04-24',
  }),
});

export type FlagName = keyof typeof FEATURE_FLAGS_CONFIG;
