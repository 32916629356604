import * as React from 'react';
import type { SVGProps } from 'react';
const PrinterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
    <g id="solid/printer">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99999 3.29995C5.99999 2.14015 6.9402 1.19995 8.09999 1.19995H15.9C17.0598 1.19995 18 2.14015 18 3.29995V7.49995C18 7.52074 17.9997 7.54146 17.9991 7.56211C18.4518 7.61716 18.9027 7.67836 19.3516 7.74562C20.6695 7.94312 21.6 9.08561 21.6 10.3835V15.3C21.6 16.7911 20.3912 18 18.9 18H18.6103L18.9766 20.3806C19.1723 21.6527 18.1881 22.8 16.901 22.8H7.09849C5.81143 22.8 4.8272 21.6527 5.02291 20.3806L5.38917 18H5.09999C3.60883 18 2.39999 16.7911 2.39999 15.3V10.3835C2.39999 9.08561 3.33044 7.94312 4.64841 7.74562C5.0973 7.67836 5.54816 7.61716 6.0009 7.56211C6.0003 7.54146 5.99999 7.52074 5.99999 7.49995V3.29995ZM16.2 7.37676C14.8154 7.25967 13.4146 7.19995 12 7.19995C10.5854 7.19995 9.18462 7.25967 7.79999 7.37676V3.29995C7.79999 3.13427 7.93431 2.99995 8.09999 2.99995H15.9C16.0657 2.99995 16.2 3.13427 16.2 3.29995V7.37676ZM7.92926 15C7.78119 15 7.65527 15.108 7.63275 15.2543L6.80198 20.6543C6.77402 20.8361 6.91463 21 7.09849 21H16.901C17.0849 21 17.2255 20.8361 17.1975 20.6543L16.3668 15.2543C16.3443 15.108 16.2183 15 16.0703 15H7.92926Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PrinterIcon;
