import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MessageProvider, useMessage } from '@messageformat/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from '@sentry/react';
import { getGetApiProjectsProjectIdQueryOptions } from '@shape-construction/api/api';
import type { UserSchema } from '@shape-construction/api/model';
import { Toaster } from '@shape-construction/arch-ui';
import { FeatureFlagEntityProvider } from '@shape-construction/feature-flags';
import { useTitle } from '@shape-construction/hooks';
import { useIsRestoring, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ActionCableProvider } from 'action-cable/ActionCableContext';
import { ChatClientProvider } from 'app/channels/get-stream/useChatClient';
import { LoadingSpinner } from 'app/components/Loading/Loading';
import { getNewMsalInstance } from 'app/msalAuthConfig';
import { endSession, hasAuthToken } from 'app/session';
import { Provider as JotaiProvider } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-dom-last-location';
import messages from '../messages';
import { Meta } from './Meta';
import { useAnalytics } from './analytics/useAnalytics';
import { CrashPage } from './components/CrashPage/CrashPage';
import useSetupExports from './components/Exports/useSetupExports';
import { useMicrosoftLogin } from './components/LoginProviders/Microsoft/useMicrosoftLogin';
import { ErrorNotificationModal } from './components/Notifications/ErrorNotificationModal/ErrorNotificationModal';
import { ProductTour } from './components/ProductTour/ProductTour';
import { environment } from './config/environment';
import { LayoutProvider } from './contexts/layout/layoutContext';
import { Layout } from './hoc/Layout/Layout';
import { InstallAppProvider } from './hooks/useInstallApp';
import { NotificationReaderObserver } from './pages/notifications/NotificationReaderObserver';
import { useSyncPausedMutations } from './queries/offline/useSyncPausedMutations';
import queryClient from './queries/query.client.builder';
import { createPersister } from './queries/query.client.persister';
import { useUsersMe } from './queries/users/users';
import { theme } from './theme';

const persister = createPersister(environment.REACT_QUERY_PERSISTER_KEY);

const PrivateRoutesLazy = React.lazy(() => import('./pages/PrivateRoutes'));
const PublicRoutesLazy = React.lazy(() => import('./pages/PublicRoutes'));

const msalInstance = getNewMsalInstance();
msalInstance.initialize();

const Routes: React.FC<{ user: UserSchema | undefined }> = ({ user }) => {
  useSetupExports();

  if (!user) return <PublicRoutesLazy />;

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <ProductTour />
      <PrivateRoutesLazy key={user.id} />
    </div>
  );
};

const AppContent = ({ onDefaultsMutationSet }: { onDefaultsMutationSet: () => void }) => {
  const isRestoring = useIsRestoring();
  const { data: user, isLoading, isFetching } = useUsersMe();
  const { isMicrosoftLoginLoading, setIsMicrosoftLoginLoading, handleSuccess } = useMicrosoftLogin();
  const location = useLocation();
  const projectId = useMemo(
    () => matchRoutes([{ path: '/projects/:projectId/*' }], location)?.[0]?.params?.projectId,
    [location]
  );
  const { data: project } = useQuery(getGetApiProjectsProjectIdQueryOptions(projectId!));
  const isPendingUser = isLoading || isFetching;

  useTitle(useMessage('meta.title'));

  /**
   * this useEffect is used to handle the creation of the user session after the microsoft login
   * this is located here to avoid having the handleRedirectPromise in the useMicrosoftLogin hook, since it's triggering some issues on the user logout
   */
  useEffect(() => {
    msalInstance.handleRedirectPromise().then((response) => {
      if (response?.account) {
        setIsMicrosoftLoginLoading(true);
        handleSuccess(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSyncPausedMutations(queryClient, onDefaultsMutationSet);

  useAnalytics();

  if (isPendingUser || isRestoring || isMicrosoftLoginLoading) return <LoadingSpinner variant="screen" />;

  if (user && !hasAuthToken()) {
    // Something is wrong!!!
    // The session token was removed but the user is still on cache. Force reset!
    endSession();
    return <LoadingSpinner variant="screen" />;
  }

  return (
    <JotaiProvider key={projectId}>
      <FeatureFlagEntityProvider userId={user?.id} teamId={project?.currentTeamId}>
        <LayoutProvider>
          <Layout>
            <React.Suspense fallback={<LoadingSpinner variant="screen" />}>
              <Routes user={user} />
            </React.Suspense>
          </Layout>
        </LayoutProvider>
      </FeatureFlagEntityProvider>
    </JotaiProvider>
  );
};

export const App = () => {
  const navigate = useNavigate();
  const [defaultsMutationSet, setDefaultsMutationSet] = React.useState(false);
  const [hydratedQueryClient, setHydratedQueryClient] = React.useState(false);

  useEffect(() => {
    if (defaultsMutationSet && hydratedQueryClient) {
      queryClient.resumePausedMutations();
    }
  }, [defaultsMutationSet, hydratedQueryClient]);

  return (
    <CookiesProvider>
      <MuiThemeProvider theme={theme}>
        <MessageProvider messages={messages} locale="en">
          <Meta />
          <CssBaseline />
          <MsalProvider instance={msalInstance}>
            <GoogleOAuthProvider clientId={environment.GOOGLE_CLIENT_ID}>
              <main className="bg-white print:hidden">
                <ErrorBoundary
                  fallback={({ resetError }) => <CrashPage resetError={resetError} />}
                  onReset={() => navigate('/')}
                >
                  <PersistQueryClientProvider
                    client={queryClient}
                    persistOptions={{ persister }}
                    onSuccess={() => setHydratedQueryClient(true)}
                  >
                    {!environment.DISABLE_REACT_QUERY_DEV_TOOLS && (
                      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
                    )}
                    <ChatClientProvider>
                      <LastLocationProvider>
                        <InstallAppProvider>
                          <Toaster />
                          <NotificationReaderObserver />
                          <ErrorNotificationModal />
                          <ActionCableProvider>
                            <AppContent onDefaultsMutationSet={() => setDefaultsMutationSet(true)} />
                          </ActionCableProvider>
                        </InstallAppProvider>
                      </LastLocationProvider>
                    </ChatClientProvider>
                  </PersistQueryClientProvider>
                </ErrorBoundary>
              </main>
            </GoogleOAuthProvider>
          </MsalProvider>
        </MessageProvider>
      </MuiThemeProvider>
    </CookiesProvider>
  );
};
