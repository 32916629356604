import React from 'react';

export enum SHAPE {
  BASIC = 'BASIC',
  ROUNDED = 'ROUNDED',
}

export enum THEME {
  BLUE = 'BLUE',
  GRAY = 'GRAY',
  GRAY_DARK = 'GRAY_DARK',
  GREEN = 'GREEN',
  GREEN_DARK = 'GREEN_DARK',
  PINK = 'PINK',
  PINK_DARK = 'PINK_DARK',
  PURPLE_DARK = 'PURPLE_DARK',
  RED = 'RED',
  TEAL = 'TEAL',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
  YELLOW_ALT = 'YELLOW_ALT',
}

export enum SIZE {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  LARGE = 'lg',
}

export interface BadgeProps {
  /**
   * Does the badge have a dot?
   */
  withDot?: boolean;
  /**
   * The label of the badge
   */
  label: string;
  /**
   * Handler when clicking the remove button
   */
  className?: string;
  /**
   * Custom style
   */
  onRemove?: React.MouseEventHandler;
  /**
   * Does the badge have a remove button?
   */
  withRemoveButton?: boolean;
  /**
   * The shape of the badge
   */
  shape?: SHAPE;
  /**
   * The size of the badge
   */
  size?: SIZE;
  /**
   * The colorized theme of the badge
   */
  theme?: THEME;
  /**
   * If badge should occupy only its container and truncate label
   */
  truncate?: boolean;
  /**
   * The icon of the badge
   */
  icon?: React.ElementType | null;
}

export interface InternalProps extends Required<BadgeProps> {
  /**
   * The dot component
   */
  dotChild?: React.ReactElement;
  /**
   * The remove button component
   */
  removeButtonChild?: React.ReactElement;
  /**
   * The class names needed to define the shape
   */
  shapeClassNames?: string;
  /**
   * The class names needed to define the size
   */
  sizeClassNames?: string;
  /**
   * The class names needed to define the theme
   */
  themeClassNames?: string;
  /**
   * The class names needed to define the icon
   */
  iconClassNames?: string;
}
