import React from 'react';
import { cn } from '../../utils/classes';

type PopoverContentBodyProps = React.PropsWithChildren<React.ComponentProps<'p'>>;

export const PopoverContentBody: React.FC<PopoverContentBodyProps> = ({ children, className, ...props }) => {
  return (
    <p className={cn('font-normal text-sm max-h-96 overflow-y-auto', className)} id="popover-body" {...props}>
      {children}
    </p>
  );
};
