import type {
  IssueSchema,
  IssueViewFilterItemSchema,
  IssueViewFilterItemSchemaName,
  IssueViewListSchema,
} from '@shape-construction/api/model';
import { parseDateWithFormat } from '@shape-construction/utils/DateTime';
import type { PredefinedOptionKey } from 'app/components/Filters/DateSelect/DateSelectOptions';
import { dateFilterToViewPropertyMapping, emptyFilters } from '../constants';
import type { IssuesFilterValue, IssuesFilters, IssuesFiltersFormDateKeys } from '../types/types';

export const mapSavedViews = (viewTabs: IssueViewListSchema | undefined) => {
  return Object.fromEntries(
    (viewTabs ?? []).map((issueView) => {
      const viewPartial = {
        group_by: issueView.groupBy,
        group_properties: issueView.groupProperties,
        sort_by: issueView.sortBy,
        sort_order: issueView.sortOrder,
      };
      const viewObject = issueView.filterProperties.reduce(transformFilterObject, viewPartial) as IssuesFilters;

      return [issueView.id, viewObject];
    })
  );
};

const transformFilterObject = (acc: Partial<IssuesFilters>, { name, value }: IssueViewFilterItemSchema) => {
  let tempAcc: Partial<IssuesFilters> = { ...emptyFilters, ...acc };
  const dateFilterName = getDateFilterNameFromViewProperty(name);

  tempAcc = dateFilterName
    ? transformDateFilter(tempAcc, name, dateFilterName, value as IssuesFilterValue)
    : transformNonDateFilter(tempAcc, name, value as IssuesFilterValue);

  return tempAcc;
};

const getDateFilterNameFromViewProperty = (viewProperty: IssueViewFilterItemSchemaName): IssuesFiltersFormDateKeys => {
  return Object.entries(dateFilterToViewPropertyMapping).find(
    ([, mapping]) => mapping.start === viewProperty || mapping.end === viewProperty || mapping.relative === viewProperty
  )?.[0] as IssuesFiltersFormDateKeys;
};

const transformDateFilter = (
  acc: Partial<IssuesFilters>,
  name: IssueViewFilterItemSchemaName,
  dateFilterName: IssuesFiltersFormDateKeys,
  value: IssuesFilterValue
) => {
  if (name.includes('_relative')) {
    const updatedDate = {
      date: undefined,
      end_date: undefined,
      relative_date: value as PredefinedOptionKey,
    };
    return { ...acc, [dateFilterName]: updatedDate };
  }

  const startOrEnd = name.includes('_start') ? 'date' : 'end_date';
  const updatedDate = {
    ...acc[dateFilterName],
    [startOrEnd]: parseDateWithFormat(value as Date, 'YYYY-MM-DD'),
    relative_date: 'custom',
  };
  return { ...acc, [dateFilterName]: updatedDate };
};

const transformNonDateFilter = (
  acc: Partial<IssuesFilters>,
  name: IssueViewFilterItemSchemaName,
  value: IssuesFilterValue
) => {
  if (name === 'critical') return { ...acc, [name]: value as IssuesFilters['critical'] };
  if (name === 'visibility_status') return { ...acc, [name]: value as IssueSchema['visibilityStatus'] };
  return { ...acc, [name]: Array.isArray(value) ? value : [value] };
};
