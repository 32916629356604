import { useMessageGetter } from '@messageformat/react';
import type { QueuedTaskSchema } from '@shape-construction/api/model';
import { Button, Tooltip } from '@shape-construction/arch-ui';
import { useMemo } from 'react';
import { exportsAtom } from './state/exportsAtom';
import useQueueToasts from './useQueueToasts';

const operationName = {
  issue_export: 'issue',
  issue_list_export: 'bulk issue',
  shift_activity_list_export: 'shift activity',
  shift_report_export: 'shift report',
  shift_report_list_export: 'bulk shift report',
  weekly_work_plan_export: 'weekly work plan',
  weekly_work_plan_lookback_export: 'weekly work plan lookback',
};

const useExports = () => {
  const messages = useMessageGetter('exports');

  const createAction = (job: QueuedTaskSchema) =>
    job.result && (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <a href={job?.result.fileUrl} download target="_blank" rel="noreferrer">
            <Button variant="outlined" color="white" size="sm" onClick={() => removeFromCompletedJobs(job.id)}>
              <span className="flex flex-row">
                <span className="shrink-0">
                  {messages('download')}
                  {` "`}
                </span>
                <span className="flex-1 break-all line-clamp-1">{job.result.filename}</span>"
              </span>
            </Button>
          </a>
        </Tooltip.Trigger>
        <Tooltip.Content side="bottom" className="z-popover">
          {job.result.filename}
        </Tooltip.Content>
      </Tooltip.Root>
    );

  const toastMessages = {
    success: messages('toasts.success'),
    loading: (job: QueuedTaskSchema) => {
      return messages('toasts.loading', { exportType: operationName[job.operation] });
    },
    failed: (job: QueuedTaskSchema) => {
      return messages('toasts.failed', { exportType: operationName[job.operation] });
    },
  };

  const {
    addToPendingJobs,
    addToCompletedJobs,
    removeFromCompletedJobs,
    jobQueue,
    initToastsFromStorage,
    showFailedJobToast,
  } = useQueueToasts<QueuedTaskSchema>('exports', exportsAtom, toastMessages, createAction);

  const triggerDownload = (file: QueuedTaskSchema['result']) => {
    if (file) {
      const link = document.createElement('a');
      link.href = file.fileUrl;
      link.download = file.filename ?? 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getTimeLeft = (expiryDateStr: string) => {
    const currentDate = new Date();
    const expiryTime = new Date(expiryDateStr).getTime();
    const timeLeft = expiryTime - currentDate.getTime();

    return timeLeft;
  };

  const isValid = (expiryDateStr: string) => {
    return getTimeLeft(expiryDateStr) > 0;
  };

  const addToCompletedExports = (job: QueuedTaskSchema) => {
    addToCompletedJobs(job, getTimeLeft(job?.result?.expiresAt as string));
    triggerDownload(job.result);
  };

  const dismissFailedExport = (job: QueuedTaskSchema) => {
    if (job.status === 'failed' || job.status === 'expired') {
      removeFromCompletedJobs(job.id);
      showFailedJobToast(job);
    }
  };

  const initExportToastsFromStorage = () => {
    const filteredExports = jobQueue.filter((job) => {
      if (job.status === 'pending') return true;

      if (job.status === 'completed' && job.result?.expiresAt && isValid(job.result?.expiresAt)) {
        return true;
      }

      return false;
    });

    initToastsFromStorage(filteredExports);
  };

  const pendingExportIds = useMemo(() => {
    return jobQueue.filter((job) => job.status === 'pending').map((job) => job.id);
  }, [jobQueue]);

  return {
    addToPendingExports: addToPendingJobs,
    addToCompletedExports,
    removeFromCompletedExports: removeFromCompletedJobs,
    exportsQueue: jobQueue,
    initToastsFromStorage: initExportToastsFromStorage,
    dismissFailedExport,
    pendingExportIds,
  };
};

export default useExports;
