import React, {
  createContext,
  type ReactNode,
  useContext,
  useState,
  type ComponentProps,
  useMemo,
  useCallback,
} from 'react';

type ModalProps<T = any> = {
  id: string;
  props?: T;
};

type ModalRegistry<M extends Record<string, any>> = {
  [K in keyof M]: React.FC<M[K]>;
};

type ModalManagerContextType<M extends Record<string, any>> = {
  openModal: <K extends keyof M>(id: K, props?: ComponentProps<M[K]>) => void;
  closeModal: <K extends keyof M>(id?: K) => void;
};

const ModalManagerContext = createContext<ModalManagerContextType<any> | undefined>(undefined);

const ModalManagerProvider = <M extends Record<string, any>>({
  modals,
  children,
}: {
  modals: ModalRegistry<M>;
  children: ReactNode;
}) => {
  const [activeModals, setActiveModals] = useState<ModalProps[]>([]);

  const openModal: ModalManagerContextType<M>['openModal'] = useCallback((id, props) => {
    if (!modals[id]) {
      console.warn(`Modal with id "${String(id)}" is not registered.`);
      return;
    }
    setActiveModals((prev) => [...prev, { id: String(id), props }]);
  }, []);

  const closeModal: ModalManagerContextType<M>['closeModal'] = useCallback((id) => {
    if (id) {
      setActiveModals((prev) => prev.filter((modal) => modal.id !== String(id)));
    } else {
      setActiveModals([]);
    }
  }, []);

  const value = useMemo(() => ({ openModal, closeModal }), [openModal, closeModal]);

  return (
    <ModalManagerContext.Provider value={value}>
      {children}
      {activeModals.map(({ id, props }) => {
        const ModalComponent = modals[id];
        return (
          ModalComponent && <ModalComponent key={id} {...(props || {})} onClose={() => closeModal(id as keyof M)} />
        );
      })}
    </ModalManagerContext.Provider>
  );
};

const useModalManager = <M extends Record<string, any>>(): ModalManagerContextType<M> => {
  const context = useContext(ModalManagerContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalManagerProvider');
  }
  return context;
};

export { ModalManagerProvider, useModalManager };
